






import Vue from 'vue'
import firebase from 'firebase'
import { auth } from 'firebaseui'
import firebaseApp, { analytics } from '@/plugins/firebase'

export default Vue.extend({
  mounted () {
    this.initAuth()
  },
  methods: {
    getAuthConfig (): auth.Config {
      return {
        signInOptions: [
          {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
          },
          firebase.auth.TwitterAuthProvider.PROVIDER_ID,
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            customParameters: {
              prompt: 'select_account'
            }
          },
          firebase.auth.FacebookAuthProvider.PROVIDER_ID
        ],
        callbacks: {
          // Called when the user has been successfully signed in.
          signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            if (authResult.user) {
              analytics.logEvent('login', { location: 'nav' })
              this.$store.dispatch('handleLoggedIn', authResult.user)
            }
            this.$router.push({ name: 'Home' })
            return false
          }
        },
        signInFlow: 'popup',
        // Terms of service url.
        tosUrl: 'https://createan.email',
        // Privacy policy url.
        privacyPolicyUrl: 'https://createan.email'
      }
    },
    initAuth () {
      const ui = new auth.AuthUI(firebaseApp.auth())
      ui.start('#firebaseui-auth-container', this.getAuthConfig())
    }
  }
})
